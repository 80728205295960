import React from "react";
// import sections
import Hero from "../components/sections/Hero";

const Home = () => {
  return (
    <>
      <div class="form-body">
        <h3>Redirection....</h3>
      </div>
      <div class="form-body">
        <div className="illustration-section-01">Redirecting.......</div>
      </div>
    </>
  );
};

export default Home;
