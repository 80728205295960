import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import "./style.css";
import axios from "axios";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    identifier: "",
    signupType: "",
    transactionType: "",
    transactionIdentifier: "",
    amount: "",
    currency: "",
    productKey: "",
  });

  const {
    name,
    email,
    identifier,
    signupType,
    transactionType,
    transactionIdentifier,
    amount,
    currency,
    productKey,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    let customerData = {
      name: name,
      email: email,
      metadata: {
        identifier: identifier,
      },
    };
    window.btHandler.createSignup(name, email, identifier, signupType);
    // await axios
    //   .post(
    //     "https://sit-api.bufferloop.com/vendor/test/create/customer",
    //     customerData
    //   )
    //   .then((res) => {
    //     console.log(res);

    //     // After successfully creating a customer call createSignup
    //     return res.data;
    //   });
    alert("Create Signup API called successfully");
  };

  const onSubmitTransaction = async (e) => {
    e.preventDefault();
    let transactionData = {
      amount: amount,
      stripeCustomerId: "cus_N2qlrFRktmVfud",
      customerKey: identifier,
      metadata: {
        customerKey: identifier,
        transactionIdentifier: transactionIdentifier,
        identifier: identifier,
      },
      metaData: {
        customerKey: identifier,
        transactionIdentifier: transactionIdentifier,
        identifier2: identifier,
      },
      payment_intent_data: {
        metadata: {
          customerKey: identifier,
          transactionIdentifier: transactionIdentifier,
          identifier: identifier,
        },
        metaData: {
          customerKey: identifier,
          transactionIdentifier: transactionIdentifier,
          identifier2: identifier,
        },
      },
    };
    /*  await axios
      .post(
        "https://sit-api.bufferloop.com/vendor/test/create/transaction",
        transactionData
      )
      .then((res) => {
        console.log("checkout session....", res?.data?.session?.url); */

    // After successfully creating a transaction.... call createTransaction
    window.btHandler.createTransaction(
      name,
      email,
      identifier,
      transactionType,
      transactionIdentifier,
      amount,
      currency,
      productKey
    );
    /* if (res?.data?.session?.url) {
          window.open(res?.data?.session?.url);
        } else {
          console.log("no url....");
        } */
    alert("Transaction API called successfully");
    // });
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <>
      <section {...props} className={outerClasses}>
        {/* <div className="container-sm"></div> */}
        <div className="container mt-5">
          <form onSubmit={(e) => onSubmit(e)} className="form">
            <label> Customer SignUp Form</label>
            <div className="form-body">
              <input
                className="form-control"
                type="text"
                placeholder="Name*"
                name="name"
                value={name}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-body">
              <input
                className="form-control"
                type="email"
                placeholder="Email*"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-body">
              <input
                className="form-control"
                type="text"
                placeholder="Customer Identifier*"
                name="identifier"
                value={identifier}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-body">
              <input
                className="form-control"
                type="text"
                placeholder="Signup Type*"
                name="signupType"
                value={signupType}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <button className="btn btn-primary" type="submit">
              Create Signup
            </button>
          </form>
        </div>
        {/* </section>
      <section {...props} className={outerClasses}> */}
        {/* <div className="container-sm"></div> */}
        <div className="container mt-5">
          <form onSubmit={(e) => onSubmitTransaction(e)} className="form">
            <label> Customer Transaction Form</label>
            <div className="form-body">
              <input
                className="form-control"
                type="text"
                placeholder="Name*"
                name="name"
                value={name}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-body">
              <input
                className="form-control"
                type="email"
                placeholder="Email*"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-body">
              <input
                className="form-control"
                type="text"
                placeholder="Customer Identifier*"
                name="identifier"
                value={identifier}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-body">
              <input
                className="form-control"
                type="text"
                placeholder="Transaction Type*"
                name="transactionType"
                value={transactionType}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-body">
              <input
                className="form-control"
                type="text"
                placeholder="Transaction Identifier*"
                name="transactionIdentifier"
                value={transactionIdentifier}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-body">
              <input
                className="form-control"
                type="number"
                placeholder="Amount*"
                name="amount"
                value={amount}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-body">
              <input
                className="form-control"
                type="text"
                placeholder="Currency*"
                name="currency"
                value={currency}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-body">
              <input
                className="form-control"
                type="text"
                placeholder="productKey*"
                name="productKey"
                value={productKey}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <button className="btn btn-primary mt-1" type="submit">
              Create Transaction
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
